<template>
  <div class="credit-line-container">
    <!-- Botón de Regreso -->

      <button class="btn-back" @click="goBack">
        <i class="fas fa-arrow-left"></i> Go Back
      </button>

    <!-- Contenido Principal -->
    <div class="main-content" v-if="!loading">
      <div class="credit-line-info" v-if="whatsappBusinessAccount">
        <!-- Información de la Cuenta de WhatsApp Business -->
        <div class="account-info">
          <h2>
            Manage Credit Line for {{ whatsappBusinessAccount.name }} ({{ whatsappBusinessAccount.id }})
          </h2>
          <p>
            <strong>Owner Business:</strong> {{ whatsappBusinessAccount.owner_business_info.name }} ({{ whatsappBusinessAccount.owner_business_info.id }})
          </p>
          <p>
            <strong>Primary Funding ID:</strong> {{ whatsappBusinessAccount.primary_funding_id }}
          </p>
        </div>

        <!-- Acciones de la API de WhatsApp -->
        <div class="api-actions">
          <h3>Permissions needed for:</h3>
          <div class="actions-list">
            <button class="btn-whatsapp" @click="shareCreditLine">
              <i class="fas fa-share"></i> Share Credit Line
            </button>
            <button class="btn-whatsapp" @click="verifyCreditLine">
              <i class="fas fa-check-circle"></i> Verify Credit Line
            </button>
            <button class="btn-danger" @click="revokeCreditLine">
              <i class="fas fa-times-circle"></i> Revoke Credit Line
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <p>WhatsApp Business Account not found.</p>
      </div>
    </div>
    <SpinnerLoader v-else />
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { WhatsappBusinessAccount } from '../services/AccountService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const whatsappBusinessAccount = ref<WhatsappBusinessAccount | undefined>(undefined)

function goBack () {
  router.back()
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function shareCreditLine () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.shareCreditLine(whatsappBusinessAccount.value.id)
      toastSuccess('Share Credit Line Response: ' + response)
    } catch (error) {
      toastError('Share Credit Line Error: ' + error)
    }
  }
  loading.value = false
}

async function verifyCreditLine () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.verifyCreditLine(whatsappBusinessAccount.value.id)
      toastSuccess('Verify Credit Line Response: ' + JSON.stringify(response))
    } catch (error) {
      toastError('Verify Credit Line Error: ' + error)
    }
  }
  loading.value = false
}

async function revokeCreditLine () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.revokeCreditLine(whatsappBusinessAccount.value.id)
      toastSuccess('Revoke Credit Line Response: ' + response)
    } catch (error) {
      toastError('Revoke Credit Line Error: ' + error)
    }
  }
  loading.value = false
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getWhatsappBusinessAccount (wabaId: string) {
  try {
    const accountService = await AccountService.getInstance()
    whatsappBusinessAccount.value = await accountService.getWhatsappBusinessAccount(wabaId)
  } catch {
    toastError('Error getting whatsapp business account')
    router.push({ name: 'home' })
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  const wabaId = route.params.wabaId as string

  if (wabaId) {
    await getWhatsappBusinessAccount(wabaId)
  } else {
    router.push({ name: 'home' })
  }

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style scoped lang="scss">
/* Variables de Color */
$primary-color: #0052CC;
$secondary-color: #6B778C;
$success-color: #36B37E;
$danger-color: #FF5630;
$whatsapp-color: #25D366;
$background-color: #F4F5F7;
$text-color: #172B4D;
$border-color: #DFE1E6;

/* Importar Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Estilos Generales */
.credit-line-container {
  padding: 20px;
  background-color: $background-color;
  color: $text-color;
  height: 100%;

  .btn-back {
      @extend .btn;
      background: none;
      border: none;
      color: $primary-color;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      i {
        margin-right: 5px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

  .main-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 30px;

    .account-info {
      margin-bottom: 30px;

      h2 {
        margin-top: 0;
        font-size: 24px;
        margin-bottom: 15px;
      }

      p {
        margin: 5px 0;
        font-size: 16px;
        color: $secondary-color;

        strong {
          color: $text-color;
        }
      }
    }

    .api-actions {
      h3 {
        margin-top: 0;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .actions-list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .btn-whatsapp,
        .btn-danger {
          @extend .btn;
          background-color: $whatsapp-color;
          border-color: $whatsapp-color;
          color: #fff;
          display: flex;
          align-items: center;

          i {
            margin-right: 10px;
          }

          &:hover {
            background-color: darken($whatsapp-color, 5%);
            border-color: darken($whatsapp-color, 5%);
          }
        }

        .btn-danger {
          background-color: $danger-color;
          border-color: $danger-color;

          &:hover {
            background-color: darken($danger-color, 5%);
            border-color: darken($danger-color, 5%);
          }
        }
      }
    }
  }
}

/* Estilos para Botones */
.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  text-decoration: none;

  &:hover {
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }
}
</style>
