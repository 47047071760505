<template>
  <div class="login-container">
    <div class="login" v-if="!loading">
      <div class="login-form">
        <h2>Login</h2>
        <div class="input">
          <label for="email">Email Address</label>
          <input
            type="text"
            id="email"
            placeholder="email@address.com"
            v-model="email"
          />
        </div>
        <div class="input">
          <label for="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="password123"
            v-model="password"
          />
        </div>
        <button class="btn-primary" @click="loginWithEmailAndPassword">
          Login
        </button>
        <div class="alternative-option">
          Don't have an account?
          <span @click="moveToRegister">Register</span>
        </div>
      </div>
      <div class="alternative-login-options">
        <button @click="loginWithGoogle" class="btn-google">
        <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" />
        Sign in with Google
      </button>
      <button @click="loginWithFacebook" class="btn-facebook">
        <i class="fab fa-facebook-f"></i> Login with Facebook
      </button>
      </div>
      <!-- Alertas o mensajes adicionales -->
      <div
        class="alert alert-warning alert-dismissible fade show mt-5 d-none"
        role="alert"
        id="alert_1"
      >
        <!-- Mensaje de alerta -->
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
    <SpinnerLoader v-else />
  </div>
</template>

<script setup lang="ts">
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth'
import { POSITION, useToast } from 'vue-toastification'
import AuthService from '../services/AuthService/AuthService'
import { useRouter, useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'

const router = useRouter()
const route = useRoute()
const toast = useToast()
const email = ref('')
const password = ref('')
const loading = ref(true)

onMounted(async () => {
  await router.isReady()
  const authService = await AuthService.getInstance()
  if (authService.currentUser.value != null) {
    router.push({ name: 'home', query: route.query })
  }
  loading.value = false
})

async function loginWithEmailAndPassword () {
  const auth = getAuth()
  loading.value = true

  if (email.value === '' || password.value === '') {
    toastError('Email and password are required')
    loading.value = false
    return
  }

  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((result) => {
      const user = result.user
      handleUserLogin(user)
    })
    .catch((error) => {
      toastError(error)
      loading.value = false
    })
}

function moveToRegister () {
  router.push('/register')
}

async function loginWithGoogle () {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  loading.value = true

  signInWithPopup(auth, provider)
    .then((result) => {
      // The user is signed in with Google.
      const user = result.user
      handleUserLogin(user)
    })
    .catch((error) => {
      toastError(error)
      loading.value = false
    })
}

async function loginWithFacebook () {
  const auth = getAuth()
  const provider = new FacebookAuthProvider()
  loading.value = true

  signInWithPopup(auth, provider)
    .then((result) => {
      // The user is signed in with Facebook.
      const user = result.user
      handleUserLogin(user)
    })
    .catch((error) => {
      toastError(error)
      loading.value = false
    })
}

async function handleUserLogin (user: any) {
  const token = await user.getIdToken()
  // send token to backend
  const authService = await AuthService.getInstance(token)
  await authService.loginWithIdToken()
  // redirect to dashboard
  router.push('/home')
}

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style scoped lang="scss">
/* Reset de Márgenes y Paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Variables de Color */
$primary-color: #0052CC;
$secondary-color: #6B778C;
$background-color: #F4F5F7;
$text-color: #172B4D;
$border-color: #DFE1E6;

/* Importar Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Estilos para la sección de Login */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $background-color;
  color: $text-color;

  /* Evitamos que el contenedor se desborde */
  overflow: hidden;

  .login {
    background-color: #fff;
    padding: 30px; /* Reducimos el padding */
    width: 100%;
    max-width: 400px;
    border: 1px solid $border-color;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Reducimos el gap */
    box-sizing: border-box;

    h2 {
      margin-top: 0;
      font-size: 24px;
      text-align: center;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      gap: 10px; /* Reducimos el gap */

      .input {
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-weight: bold;
          font-size: 14px;
        }

        input {
          padding: 8px; /* Reducimos el padding */
          border: 1px solid $border-color;
          border-radius: 4px;
          font-size: 14px;
        }
      }

      .btn-primary {
        @extend .btn;
        width: 100%;
        font-size: 16px;
        padding: 10px; /* Ajustamos el padding */
      }

      .alternative-option {
        text-align: center;
        font-size: 14px;

        span {
          color: $primary-color;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .alternative-login-options {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px; /* Reducimos el margen superior */

      .btn-google,
      .btn-facebook {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 500;
      }

      .btn-google {
        background-color: #fff;
        color: #000;
        border: 1px solid #ccc;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        &:hover {
          background-color: #f7f7f7;
        }
      }

      .btn-facebook {
        background-color: #1877F2;
        color: #fff;
        border: none;

        i {
          margin-right: 10px;
          font-size: 20px;
        }

        &:hover {
          background-color: darken(#1877F2, 5%);
        }
      }
    }
  }
}

/* Estilos para Botones */
.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  user-select: none;
  padding: 8px 16px; /* Ajustamos el padding */
  line-height: 1.5;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  text-decoration: none;

  &:hover {
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;

  &:hover {
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }
}
</style>
