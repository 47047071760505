<template>
  <div class="phone-number-container">
    <div class="go-back">
      <button class="btn btn-secondary" @click="goBack">
        <i class="fas fa-arrow-left"></i> Go Back
      </button>
    </div>
    <div class="phone-number-loaded" v-if="!loading && phoneNumber">
      <div class="phone-number-card">
        <div class="phone-number-header">
          <h2>{{ phoneNumber.verified_name }}</h2>
          <p><strong>ID:</strong> {{ phoneNumber.id }}</p>
          <p><strong>Number:</strong> {{ phoneNumber.display_phone_number }}</p>
          <div class="action-buttons">
            <button class="btn btn-primary" @click="goToMessageTemplates">
              <i class="fas fa-envelope"></i> Message Templates
            </button>
            <button class="btn btn-primary" @click="goToBusinessProfile">
              <i class="fas fa-briefcase"></i> Business Profile
            </button>
          </div>
        </div>
        <div class="phone-number-body">
          <!-- Webhooks Section -->
          <div class="webhooks-section">
            <h3>Webhooks Configuration</h3>
            <div class="webhooks-list">
              <div class="webhook-item" v-for="webhook in phoneNumber.webhooks" :key="webhook.id">
                <div class="webhook-details">
                  <label>URL:</label>
                  <input type="text" v-model="webhook.url" placeholder="https://..." />
                </div>
                <div class="webhook-options">
                  <label>
                    <input type="checkbox" v-model="webhook.enabled" /> Enabled
                  </label>
                  <div class="webhook-stats">
                    <p><strong>Errors:</strong> {{ webhook.error_count }} / {{ webhook.error_limit }}</p>
                    <p><strong>Disabled at:</strong> {{ webhook.disabled_at }}</p>
                  </div>
                </div>
                <button class="btn btn-danger btn-sm" @click="deleteWebhook(webhook.id)">
                  <i class="fas fa-trash-alt"></i> Delete
                </button>
              </div>
            </div>
            <button class="btn btn-secondary" @click="addWebhook">
              <i class="fas fa-plus"></i> Add Webhook
            </button>
          </div>
          <!-- Testing Mode Section -->
          <div class="testing-mode-section" v-if="isSuperUser">
            <h3>Testing Mode</h3>
            <div class="testing-mode-toggle">
              <label>
                <input type="checkbox" v-model="phoneNumber.testing_mode" />
                Enable Testing Mode
              </label>
              <p class="info-text">
                <i class="fas fa-info-circle"></i>
                In testing mode, only outbound message templates will be simulated. Messages will not be sent to WhatsApp, and a random WAMID will be generated.
              </p>
            </div>
          </div>
          <!-- Health Status Section -->
          <div class="health-status-section" v-if="isSuperUser">
            <h3>Health Status</h3>
            <button class="btn btn-primary" @click="getPhoneHealthStatus">
              <i class="fas fa-heartbeat"></i> Get Health Status
            </button>
            <pre>{{ healthStatusResponse }}</pre>
          </div>
        </div>
        <div class="phone-number-footer">
          <button class="btn btn-success" @click="savePhoneNumber">
            <i class="fas fa-save"></i> Save
          </button>
          <button class="btn btn-danger" @click="deletePhoneNumber">
            <i class="fas fa-trash-alt"></i> Delete
          </button>
          <button class="btn btn-whatsapp" @click="openVerifyPhoneModal">
            <i class="fab fa-whatsapp"></i> Request PIN and Verify
          </button>
        </div>
      </div>
    </div>
    <SpinnerLoader v-else />
    <!-- Modal para verificar número de teléfono -->
    <div v-if="isVerifyPhoneModalOpen" class="modal-overlay">
        <div class="modal-content">
          <span class="close-button" @click="closeVerifyPhoneModal">&times;</span>
          <h3>Verify Phone Number</h3>
          <p>Please enter the PIN code sent to your phone.</p>
          <form @submit.prevent="verifyPhoneNumber">
            <div class="form-group">
              <label for="pinCode">PIN Code</label>
              <input type="text" id="pinCode" v-model="pinCode" required />
            </div>
            <div class="form-actions">
              <button type="submit" :disabled="!isVerifyButtonEnabled" class="btn btn-primary">Verify</button>
              <button type="button" @click="closeVerifyPhoneModal" class="btn btn-secondary">Cancel</button>
            </div>
          </form>
          <div class="resend-pin">
            <button type="button" @click="resendPin" :disabled="isResendPinDisabled" class="btn btn-link">
              Resend PIN
            </button>
            <p v-if="isResendPinDisabled">You can resend the PIN in {{ resendPinTimer }} seconds.</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref, computed, watch } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { PhoneNumber } from '../services/AccountService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const phoneNumber = ref<PhoneNumber | undefined>(undefined)

const healthStatusResponse = ref<any>({})

const isVerifyPhoneModalOpen = ref(false)
const pinCode = ref('')
const isVerifyButtonEnabled = computed(() => {
  return pinCode.value.trim().length > 0
})
const isResendPinDisabled = ref(true)
const resendPinTimer = ref(60)
let resendPinInterval: any = null

const isSuperUser = computed(() => {
  return currentUser.value?.roles.includes('superuser')
})

function cleanPinCode (value: string) {
  return value.replace(/\D/g, '') // Solo números
}

watch(pinCode, (newValue: string) => {
  pinCode.value = cleanPinCode(newValue)
})

function openVerifyPhoneModal () {
  isVerifyPhoneModalOpen.value = true
  document.body.style.overflow = 'hidden' // Evitar scroll en el fondo
  startResendPinTimer()
  requestPin() // Solicitar PIN al abrir el modal
}

function closeVerifyPhoneModal () {
  isVerifyPhoneModalOpen.value = false
  document.body.style.overflow = 'auto' // Restaurar scroll
  resetVerifyPhoneModal()
}

function resetVerifyPhoneModal () {
  pinCode.value = ''
  isResendPinDisabled.value = true
  resendPinTimer.value = 60
  clearInterval(resendPinInterval)
}

async function verifyPhoneNumber () {
  if (phoneNumber.value) {
    loading.value = true
    const metaService = await MetaService.getInstance()
    try {
      await metaService.verifyAndRegisterPhone(phoneNumber.value.id, pinCode.value)
      toastSuccess('Phone number verified successfully')
      closeVerifyPhoneModal()
      await getPhoneHealthStatus()
    } catch (error) {
      toastError('Error verifying phone number: ' + error)
    }
    loading.value = false
  }
}

function startResendPinTimer () {
  isResendPinDisabled.value = true
  resendPinTimer.value = 60
  resendPinInterval = setInterval(() => {
    resendPinTimer.value--
    if (resendPinTimer.value <= 0) {
      isResendPinDisabled.value = false
      clearInterval(resendPinInterval)
    }
  }, 1000)
}

async function requestPin () {
  if (phoneNumber.value) {
    loading.value = true
    const metaService = await MetaService.getInstance()
    try {
      await metaService.requestPhonePin(phoneNumber.value.id)
      toastSuccess('PIN code requested successfully')
      startResendPinTimer()
    } catch (error) {
      toastError('Error requesting PIN code: ' + error)
    }
    loading.value = false
  }
}

async function resendPin () {
  await requestPin()
}

function goBack () {
  router.back()
}

function goToMessageTemplates () {
  router.push({ name: 'message-templates', params: { phoneNumberId: phoneNumber.value?.id, wabaId: phoneNumber.value?.waba_id } })
}

function goToBusinessProfile () {
  router.push({ name: 'business-profile', params: { phoneNumberId: phoneNumber.value?.id, wabaId: phoneNumber.value?.waba_id } })
}

async function savePhoneNumber () {
  loading.value = true
  if (phoneNumber.value) {
    const accountService = await AccountService.getInstance()
    try {
      await accountService.updatePhoneNumber(phoneNumber.value)
      toastSuccess('Phone updated')
    } catch (error) {
      toastError('Error updating account: ' + error)
    }
  }
  loading.value = false
}

async function getPhoneHealthStatus () {
  loading.value = true
  if (phoneNumber.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.getPhoneHealthStatus(phoneNumber.value)
      healthStatusResponse.value = response
      toastSuccess('Phone health status: ' + JSON.stringify(response))
    } catch (error) {
      toastError('Error getting phone health status: ' + error)
    }
  }
  loading.value = false
}

function deleteWebhook (webhookId: string) {
  if (phoneNumber.value) {
    delete phoneNumber.value.webhooks[webhookId]
  }
}

function addWebhook () {
  if (phoneNumber.value) {
    // generate random webhook id
    const webhookId = Math.random().toString(36).substring(7)
    const webhook = {
      id: webhookId,
      url: '',
      enabled: false
    }
    phoneNumber.value.webhooks[webhook.id] = webhook
  }
}

async function deletePhoneNumber () {
  loading.value = true
  const accountService = await AccountService.getInstance()
  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string
  try {
    await accountService.deletePhoneNumber(wabaId, phoneNumberId)
    toastSuccess('Phone number deleted')
    goBack()
  } catch (error) {
    toastError('Error deleting phone number')
  }
  loading.value = false
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getphoneNumber (wabaId: string, phoneNumberId: string) {
  try {
    const accountService = await AccountService.getInstance()
    phoneNumber.value = await accountService.getphoneNumber(wabaId, phoneNumberId)
  } catch {
    toastError('Error getting phone number')
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string

  if (phoneNumberId && wabaId) {
    await getphoneNumber(wabaId, phoneNumberId)
  } else {
    router.push({ name: 'home' })
  }

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

console.log(toastSuccess)

</script>

<style scoped lang="scss">
/* Variables de color */
$primary-color: #007bff;
$secondary-color: #6c757d;
$success-color: #28a745;
$danger-color: #dc3545;
$whatsapp-color: #25D366;
$background-color: #f8f9fa;
$text-color: #343a40;
$border-color: #dee2e6;

/* Importar Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.phone-number-container {
  padding: 20px;
  background-color: $background-color;
  color: $text-color;
  min-height: 100vh;

  .go-back {
    margin-bottom: 20px;

    .btn {
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
      }
    }
  }

  .phone-number-loaded {
    max-width: 800px;
    margin: 0 auto;

    .phone-number-card {
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .phone-number-header {
        padding: 20px;
        border-bottom: 1px solid $border-color;

        h2 {
          margin-top: 0;
        }

        .action-buttons {
          margin-top: 15px;

          .btn {
            margin-right: 10px;
            display: inline-flex;
            align-items: center;

            i {
              margin-right: 5px;
            }
          }
        }
      }

      .phone-number-body {
        padding: 20px;

        .webhooks-section,
        .testing-mode-section,
        .health-status-section {
          margin-bottom: 30px;

          h3 {
            border-bottom: 1px solid $border-color;
            padding-bottom: 10px;
            margin-bottom: 20px;
          }
        }

        /* Estilos para la sección de Webhooks */
        .webhooks-section {
          .webhooks-list {
            .webhook-item {
              border: 1px solid $border-color;
              padding: 15px;
              border-radius: 6px;
              margin-bottom: 15px;
              background-color: #f1f1f1;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;

              .webhook-details,
              .webhook-options {
                flex: 1 1 45%;
                margin-right: 10px;

                label {
                  font-weight: bold;
                }

                input[type="text"] {
                  width: 100%;
                  padding: 8px;
                  box-sizing: border-box;
                  border: 1px solid $border-color;
                  border-radius: 4px;
                }
              }

              .webhook-options {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                label {
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;

                  input[type="checkbox"] {
                    margin-right: 5px;
                  }
                }

                .webhook-stats {
                  font-size: 14px;
                  color: $secondary-color;

                  p {
                    margin: 0;
                  }
                }
              }

              .btn {
                margin-top: 10px;
                align-self: flex-start;
              }
            }
          }

          .btn {
            display: inline-flex;
            align-items: center;
            i {
              margin-right: 5px;
            }
          }
        }

        /* Ajustes en Testing Mode */
        .testing-mode-section {
          .testing-mode-toggle {
            display: flex;
            align-items: flex-start;
            margin-top: 10px;

            label {
              display: flex;
              align-items: center;
              margin-right: 15px;

              input[type="checkbox"] {
                margin-right: 10px;
              }
            }

            .info-text {
              color: $secondary-color;
              font-size: 14px;
              max-width: 600px;

              i {
                margin-right: 5px;
              }
            }
          }
        }

        /* Estilos para Health Status */
        .health-status-section {
          button {
            display: inline-flex;
            align-items: center;
            margin-bottom: 15px;

            i {
              margin-right: 5px;
            }
          }

          pre {
            background-color: #f8f9fa;
            padding: 15px;
            border-radius: 6px;
            border: 1px solid $border-color;
            max-height: 300px;
            overflow: auto;
          }
        }
      }

      .phone-number-footer {
        padding: 15px 20px;
        background-color: $background-color;
        border-top: 1px solid $border-color;
        display: flex;
        justify-content: flex-end;

        .btn {
          margin-left: 10px;
          display: inline-flex;
          align-items: center;

          i {
            margin-right: 5px;
          }
        }

        .btn-whatsapp {
          background-color: $whatsapp-color;
          color: #fff;

          &:hover {
            background-color: darken($whatsapp-color, 5%);
          }
        }
      }
    }
  }

  /* Estilos del modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

    .close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 28px;
      font-weight: bold;
      color: $secondary-color;
      cursor: pointer;

      &:hover {
        color: $text-color;
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    form {
      .form-group {
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        input {
          width: 100%;
          padding: 8px;
          box-sizing: border-box;
          border: 1px solid $border-color;
          border-radius: 4px;
        }
      }

      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        .btn {
          padding: 10px 20px;
        }
      }
    }

    .resend-pin {
      margin-top: 20px;
      text-align: right;

      .btn-link {
        padding: 0;
        background: none;
        border: none;
        color: $primary-color;
        cursor: pointer;

        &:disabled {
          color: $secondary-color;
          cursor: not-allowed;
        }
      }

      p {
        font-size: 12px;
        color: $secondary-color;
        margin-top: 5px;
      }
    }
  }
}

/* Estilos para botones */
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  text-decoration: none;

  &:hover {
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }

  &.btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
      background-color: darken($secondary-color, 5%);
      border-color: darken($secondary-color, 5%);
    }
  }

  &.btn-success {
    background-color: $success-color;
    border-color: $success-color;

    &:hover {
      background-color: darken($success-color, 5%);
      border-color: darken($success-color, 5%);
    }
  }

  &.btn-danger {
    background-color: $danger-color;
    border-color: $danger-color;

    &:hover {
      background-color: darken($danger-color, 5%);
      border-color: darken($danger-color, 5%);
    }
  }

  &.btn-whatsapp {
    background-color: $whatsapp-color;
    border-color: $whatsapp-color;

    &:hover {
      background-color: darken($whatsapp-color, 5%);
      border-color: darken($whatsapp-color, 5%);
    }
  }

  &.btn-link {
    background: none;
    border: none;
    color: $primary-color;
    padding: 0;
    font-weight: normal;

    &:hover {
      color: darken($primary-color, 5%);
      text-decoration: underline;
    }

    &:disabled {
      color: $secondary-color;
      cursor: not-allowed;
    }
  }
}
</style>
