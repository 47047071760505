<template>
  <div>
  <div class="metrics-section" v-if="currentUser && currentUser.roles.includes('adereso_staff')">
    <div>
      <h2>Metrics</h2>
    </div>
    <div class="filters">
      <div class="filter-item">
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" v-model="filters.startDate">
      </div>
      <div class="filter-item">
        <label for="endDate">End Date:</label>
        <input type="date" id="endDate" v-model="filters.endDate">
      </div>
      <div class="filter-item">
        <label for="phoneNumbers">Phone Numbers:</label>
        <Multiselect
          v-model="filters.phoneNumbers"
          :options="phoneNumbers.map(phone => ({
            value: phone.clean_display_phone_number,
            label: phone.display_phone_number
          }))"
          track-by="value"
          label="label"
          :multiple="true"
          placeholder="Select phone numbers"
          :limit="2"
          :limitText="(count) => `and ${count} more`"
        />
      </div>
      <button @click="fetchMetrics" class="btn btn-primary">Apply Filters</button>
      <button @click="downloadReport" class="btn btn-success">
        <i class="fas fa-download"></i> Download Report
      </button>
    </div>
    <div class="metrics-display">
        <div v-if="loadingMetrics">
          <SpinnerLoader />
        </div>
        <div v-else>
        <!-- Mostrar métricas en una tabla -->
          <div v-if="aggregatedMetrics.length > 0">
              <table>
                <thead>
                  <tr>
                    <th>Phone Number</th>
                    <th>WABA</th>
                    <th>Conversations</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(metric, index) in aggregatedMetrics" :key="index">
                    <td>{{ metric.phone_number }}</td>
                    <td>{{ metric.account_name }}</td>
                    <td>{{ formatNumber(metric.conversation) }}</td>
                    <td>{{ formatCost(metric.cost) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td><strong>Total</strong></td>
                    <td><strong>{{ formatNumber(totalConversations) }}</strong></td>
                    <td><strong>{{ formatCost(totalCost) }}</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div v-else>
              <p>No metrics available. Please adjust your filters.</p>
            </div>
          </div>
        </div>
    </div>
  <div class="home-container" v-else>
    <div class="home" v-if="!loading">
      <div class="client-view" v-if="currentUser && currentEstablishment">
        <div class="whatsapp-login" v-if="!currentEstablishment.has_whatsapp_credentials">
          <p>
            To start using
            <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
            <b>Adereso BSP</b>
            you must link your Whatsapp Business account
          </p>
          <div class="whatsapp-signup">
            <button class="btn btn-primary" @click="startEmbeddedSignupFlowFacebookSdk">
              WhatsApp Signup with Facebook SDK
            </button>
          </div>
        </div>
        <div class="whatsapp-accounts-container" v-else>
          <div class="whatsapp-accounts-list" v-if="whatsappBusinessAccounts.length > 0">
            <div class="whatsapp-account" @click="goToWhatsappBusinessAccount(whatsappAccount.id)" v-for="whatsappAccount in whatsappBusinessAccounts" v-bind:key="whatsappAccount.id">
              <div class="whatsapp-account-info">
                <div class="whatsapp-account-name">
                  {{ whatsappAccount.name }}
                </div>
                <div class="whatsapp-account-id">
                  {{ whatsappAccount.id }}
                </div>
              </div>
              <img src="../assets/whatsapp_green.svg" width="40" class="me-2" alt="" />
            </div>
          </div>
          <div class="whatsapp-synchronize-button">
            <p>
              You can't see any of your WABAs? Synchronize your accounts!
            </p>
            <button class="btn btn-whatsapp" @click="SynchronizeWhatsappBusinessAccounts">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Get WABAs
            </button>
          </div>
        </div>
      </div>
      <div class="no-client-view" v-else>
        <strong class="no-establishmnet-warning">
          Currently you don't have an establishment 🏛️
        </strong>
        <p class="establishment-invitation-warning">
          You can create one now or wait for an invitation to join an existing one
        </p>
        <div class="create-establishment-input">
          <h2 class='mb-3'>Create Establishment</h2>
          <div class='input'>
            <label for='email'>Establishment's name</label>
            <input
              class='form-control'
              type='text'
              name='name'
              placeholder='Your Brand'
              v-model='establishmentName'
            />
          </div>
          <button type='submit' class='mt-4 btn-pers' id='create_establishment_butoton' @click='createEstablishment'>
            Create
          </button>
        </div>
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref, reactive, computed } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { WhatsappBusinessAccount } from '../services/AccountService/types'
import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import MetaService from '@/services/MetaService/MetaService'
import Multiselect from 'vue-multiselect'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
const establishmentName = ref('')
const whatsappBusinessAccounts = ref<Array<WhatsappBusinessAccount>>([])

const loadingMetrics = ref(false)
const phoneNumbers = ref([]) // Cargar lista de números al montar
const metrics = ref([])
const filteredMetrics = ref([])
const aggregatedMetrics = ref([])
const filters = reactive({
  startDate: '',
  endDate: '',
  phoneNumbers: [] as { value: string, label: string }[]
})

const totalConversations = computed(() => {
  return aggregatedMetrics.value.reduce(
    (sum, metric) => sum + metric.conversation,
    0
  )
})

const totalCost = computed(() => {
  return aggregatedMetrics.value.reduce((sum, metric) => sum + metric.cost, 0)
})

function applyFilters () {
  filteredMetrics.value = metrics.value.filter(metric => {
    const matchesStartDate =
      !filters.startDate || new Date(metric.start) >= new Date(filters.startDate)
    const matchesEndDate =
      !filters.endDate || new Date(metric.end) <= new Date(filters.endDate)
    const matchesPhoneNumber =
      !filters.phoneNumbers.length ||
      filters.phoneNumbers.map(phone => phone.value).includes(metric.phone_number)
    return matchesStartDate && matchesEndDate && matchesPhoneNumber
  })

  const aggregation = {}
  filteredMetrics.value.forEach(metric => {
    const phoneNumber = metric.phone_number
    if (!aggregation[phoneNumber]) {
      aggregation[phoneNumber] = {
        phone_number: phoneNumber,
        account_name: metric.account_name,
        conversation: 0,
        cost: 0
      }
    }
    aggregation[phoneNumber].conversation += metric.conversation
    aggregation[phoneNumber].cost += metric.cost
  })
  aggregatedMetrics.value = Object.values(aggregation)
}

function formatCost (value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value)
}

function formatNumber (value) {
  return new Intl.NumberFormat('en-US').format(value)
}

async function getPhoneNumbers () {
  try {
    const accountService = await AccountService.getInstance()
    phoneNumbers.value = await accountService.getAllPhoneNumbers()
    filters.phoneNumbers = phoneNumbers.value.map(phone => ({
      value: phone.clean_display_phone_number,
      label: phone.display_phone_number
    }))
  } catch {
    toastError('Error getting whatsapp business account')
  }
}

async function fetchMetrics () {
  loadingMetrics.value = true
  try {
    const metaService = await MetaService.getInstance()
    const params = {}
    if (filters.startDate && filters.endDate) {
      params.start = filters.startDate
      params.end = filters.endDate
    }
    if (filters.phoneNumbers.length > 0) {
      params.phone_numbers = filters.phoneNumbers.map(phone => phone.value).join(',')
    }
    const response = await metaService.getConversationAnalytics(
      'all',
      params
    )
    metrics.value = response.data_points
    applyFilters()
  } catch (error) {
    console.error('Error fetching metrics:', error)
  }
  loadingMetrics.value = false
}

async function downloadReport () {
  loadingMetrics.value = true
  try {
    const metaService = await MetaService.getInstance()
    const params = {
      report: true
    }
    if (filters.startDate && filters.endDate) {
      params.start = filters.startDate
      params.end = filters.endDate
    }
    if (filters.phoneNumbers.length > 0) {
      params.phone_numbers = filters.phoneNumbers.map(phone => phone.value).join(',')
    }
    const response = await metaService.downloadConversationReport(
      'all',
      params
    )
    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'conversation_report.xlsx')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Error downloading report:', error)
  }
  loadingMetrics.value = false
}

async function goToWhatsappBusinessAccount (wabaId: string) {
  router.push({ name: 'whatsapp-business-account', params: { wabaId } })
}

async function getAccounts () {
  const accountService = await AccountService.getInstance()
  try {
    whatsappBusinessAccounts.value = await accountService.getWhatsappBusinessAccounts()
  } catch (error) {
    // do nothing
  }
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function handleOauthRedirect () {
  if (route.query.code) {
    try {
      const signupCode = route.query.code as string
      // Use this token to call the debug_token API and get the shared WABA's ID
      const metaService = await MetaService.getInstance()
      await metaService.exchangeCodeForToken(signupCode)
      toastSuccess('Whatsapp vinculado correctamente')
    } catch (error) {
      toastError('Error al vincular Whatsapp')
    }
    router.push('/')
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

onMounted(async () => {
  await router.isReady()

  await handleOauthRedirect()

  await getCurrentUser()

  await getEstablishment()

  if (currentEstablishment.value) {
    if (currentEstablishment.value.has_whatsapp_credentials) {
      router.push({ name: 'whatsapp-business-accounts' })
    }

    await getAccounts()
  }

  await getPhoneNumbers()
  await fetchMetrics()

  loading.value = false
})

async function createEstablishment () {
  if (establishmentName.value === '') {
    toastError('El nombre del establecimiento es requerido')
    return
  }
  const establishmentService = await EstablishmentService.getInstance()
  await establishmentService.createEstablishment(establishmentName.value)
  router.push('/')
}

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

async function SynchronizeWhatsappBusinessAccounts () {
  loading.value = true
  const metaService = await MetaService.getInstance()
  await metaService.synchronizeWhatsappBusinessAccounts()
  await getAccounts()
  loading.value = false
}

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */

function startEmbeddedSignupFlowFacebookSdk () {
  // Launch Facebook login
  FB.login(async function (response) { // eslint-disable-line no-undef
    console.log('FB.login response: ', response)
    if (response.authResponse) {
      const code = response.authResponse.code
      try {
        const metaService = await MetaService.getInstance()
        await metaService.exchangeCodeForToken(code)
        await metaService.synchronizeWithMeta()
        toastSuccess('Whatsapp vinculado correctamente')
        router.push('/whatsapp-business-accounts')
      } catch (e) {
        toastError('Error al vincular Whatsapp' + e)
      }
    } else {
      console.log('User cancelled login or did not fully authorize.')
      toastError('Error al vincular Whatsapp: User cancelled login or did not fully authorize.')
    }
  }, {
    config_id: '367108835655082', // configuration ID obtained in the previous step goes here
    response_type: 'code', // must be set to 'code' for System User access token
    override_default_response_type: true,
    extras: {
      featureType: 'only_waba_sharing', // Bypass phone number selection
      setup: {
      }
    }
  })
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped lang="scss">

$background-color: #F4F5F7;
$border-color: #DFE1E6;

.metrics-section {
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 0;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .filter-item {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        padding: 5px;
        border: 1px solid $border-color;
        border-radius: 4px;
      }
    }

    .btn {
      align-self: flex-end;
      margin-left: auto;
    }
  }

  .metrics-display {
    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid $border-color;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: $background-color;
      }
    }
  }
}

.whatsapp-signup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    width: 400px;
  }
  align-items: center;
}

.whatsapp-account {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;

  .whatsapp-account-info {
    .whatsapp-account-id {
      font-weight: bold;
    }
  }
  .whatsapp-account-actions {
    display: flex;
    align-items: center;
    button {
      margin-left: 10px;
    }
  }
}

.whatsapp-account:hover {
  scale: 1.01;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.whatsapp-accounts-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.whatsapp-synchronize-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
