<template>
  <div class="app">
    <div class="background"></div>
    <div class="navbar">
      <nav class="nav">
        <div class="nav-el" id="name_project">
          <a href="https://adere.so" target="blank">
            <img src="./assets/adereso_logo.svg" width="100" class="me-2" alt="" />
          </a>
          BSP
          <img src="./assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
        </div>
        <div class="nav-el">
          <div class="user-status" v-if="currentUser">
            {{ currentUser.email }}
            <button class='btn btn-danger btn-sm' @click='signOut'>
              Logout
            </button>
          </div>
        </div>
      </nav>
    </div>
    <router-view id="content" if="isMounted" />
  </div>
</template>

<script setup lang="ts">
import AuthService from './services/AuthService/AuthService'
import EstablishmentService from './services/EstablishmentService/EstablishmentService'
import { User } from './services/AuthService/types'
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const isMounted = ref(false)

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)

onMounted(async () => {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  watch(authService.currentUser, async (newCurrentUser, oldCurrentUser) => {
    currentUser.value = newCurrentUser
  })
  loading.value = false
  isMounted.value = true
})

async function signOut () {
  // remove token from local storage
  const authService = await AuthService.getInstance()
  authService.signOut()
  const establishmentService = await EstablishmentService.getInstance()
  establishmentService.removeCurrentEstablishment()
  router.push('/')
}

</script>

<style>
/* CUSTOM STYLE */

.config-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid black;
  background-color: lightgray;
}

.btn-whatsapp {
  background-color: white;
  color: black;
  border: 1px solid #25D366;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.user-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-top: 70px;
}

.background {
  position: fixed;
  top: 70px;
  width: 100%;
  height: calc(100vh - 70px);
  background: rgb(250, 250, 250);
  z-index: -1;
}
.navbar {
  z-index: 9999999;
  background: #fafafa;
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  box-shadow: 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.nav {
  width: 80%;
  min-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fafafa;
}

#name_project {
  font-weight: 700;
  display: flex;
  height: 30px;
  place-items: center;
  gap: 5px;
}

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input > label {
  text-align: start;
}

.input > input {
  margin-top: 6px;
  height: 38px !important;
}

/* From uiverse.io */
.btn-pers {
  position: relative;
  left: 50%;
  padding: 1em 2.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}

.btn-pers:hover {
  background-color: #198754;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translate(-50%, -7px);
}

.btn-pers:active {
  transform: translate(-50%, -1px);
}

/*  */

.alternative-option {
  text-align: center;
}

.alternative-option > span {
  color: #0d6efd;
  cursor: pointer;
}

</style>
