import { Ref, ref } from 'vue'

const backendUrl = process.env.VUE_APP_API_BASE_URL

export default class HttpService {
  token: Ref<string | undefined> = ref(undefined)

  private constructor () {
    // Check if an instance already exists and return it
    if (instance) {
      return instance
    }
    // If no instance exists, create one and assign it to the 'instance' variable
    instance = this
  }

  static async getInstance () {
    const httpService = new HttpService()
    return httpService
  }

  setToken (token: string) {
    localStorage.setItem('idToken', token)
  }

  getToken () {
    return localStorage.getItem('idToken')
  }

  removeToken () {
    localStorage.removeItem('idToken')
  }

  async get (path: string, queryParams: any = {}, responseType: string = 'json') {
    const headers = await this.getHeaders()
    const queryString = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')
    const url = `${backendUrl}${path}${queryString ? `?${queryString}` : ''}`

    const response = await fetch(url, {
      method: 'GET',
      headers,
      mode: 'cors',
      credentials: 'include'
    })

    if (response.ok) {
      if (responseType === 'json') {
        return await response.json()
      } else if (responseType === 'text') {
        return await response.text()
      } else if (responseType === 'blob') {
        return await response.blob()
      } else {
        throw new Error(`Unsupported response type: ${responseType}`)
      }
    }

    throw new Error('GET failed')
  }

  async getHeaders () {
    const token = this.getToken()
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }

  async post (path: string, body: any) {
    const headers = await this.getHeaders()

    const response = await fetch(backendUrl + path, {
      method: 'POST',
      headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    })

    if (response.ok) {
      return await response.json()
    }

    throw new Error('POST failed')
  }

  async delete (path: string, body: any = {}) {
    const headers = await this.getHeaders()

    const response = await fetch(backendUrl + path, {
      method: 'DELETE',
      headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    })

    if (response.ok || response.status === 204) {
      return
    }

    throw new Error('DELETE failed')
  }

  async patch (path: string, body: any) {
    const headers = await this.getHeaders()

    const response = await fetch(backendUrl + path, {
      method: 'PATCH',
      headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    })

    if (response.ok || response.status === 204) {
      return await response.json()
    }

    throw new Error('PATCH failed')
  }
}

let instance: HttpService | null = null // Singleton instance
