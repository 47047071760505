<template>
  <div class="media-tools-container">
    <!-- Botón de Regreso -->

      <button class="btn-back" @click="goBack">
        <i class="fas fa-arrow-left"></i> Go Back
      </button>

    <!-- Contenido Principal -->
    <div class="main-content">
      <h2>Media Tools</h2>

      <!-- Sección: Obtener Media por ID -->
      <div class="media-section">
        <h3>Get Media by ID</h3>
        <div class="form-group">
          <label for="mediaID">Media ID:</label>
          <input
            type="text"
            id="mediaID"
            v-model="mediaID"
            placeholder="Enter Media ID"
          />
        </div>
        <button class="btn-primary" @click="getMedia">
          <i class="fas fa-search"></i> Get Media URL
        </button>
        <div v-if="mediaUrlResponse" class="response">
          <h4>Media URL:</h4>
          <p>{{ mediaUrlResponse.url }}</p>
        </div>
      </div>

      <!-- Sección: Descargar Media -->
      <div class="media-section" v-if="mediaUrl">
        <h3>Download Media</h3>
        <div class="media-url-display">
          <p><strong>Media URL:</strong> {{ mediaUrl }}</p>
        </div>
        <button class="btn-primary" @click="downloadMedia">
          <i class="fas fa-download"></i> Download Media
        </button>
        <div v-if="DownloadmediaUrlResponse" class="response">
          <h4>Download Response:</h4>
          <p>{{ DownloadmediaUrlResponse }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')

const mediaUrlResponse = ref('')
const DownloadmediaUrlResponse = ref('')
const mediaID = ref('')
const mediaUrl = ref('')

async function getMedia () {
  if (mediaID.value === '') {
    toastError('Media ID is required')
    return
  }

  const metaService = await MetaService.getInstance()
  try {
    const mediaUrlResp = await metaService.getMediaUrl(mediaID.value)
    mediaUrlResponse.value = mediaUrlResp
    mediaUrl.value = mediaUrlResp.url
  } catch (error) {
    toastError(error as string)
  }
}

async function downloadMedia () {
  if (mediaUrl.value === '') {
    toastError('Media URL is required')
    return
  }

  const metaService = await MetaService.getInstance()
  try {
    const mediaUrlResp = await metaService.downloadMediaUrl(mediaID.value, mediaUrl.value)
    DownloadmediaUrlResponse.value = mediaUrlResp
  } catch (error) {
    toastError(error as string)
  }
}

function goBack () {
  router.back()
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style scoped lang="scss">
/* Variables de Color */
$primary-color: #0052CC;
$secondary-color: #6B778C;
$success-color: #36B37E;
$danger-color: #FF5630;
$whatsapp-color: #25D366;
$background-color: #F4F5F7;
$text-color: #172B4D;
$border-color: #DFE1E6;

/* Importar Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Estilos Generales */
.media-tools-container {
  padding: 20px;
  background-color: $background-color;
  color: $text-color;
  height: 100%;

  .btn-back {
      @extend .btn;
      background: none;
      border: none;
      color: $primary-color;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      i {
        margin-right: 5px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

  .main-content {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 30px;

    h2 {
      margin-top: 0;
      font-size: 24px;
      margin-bottom: 20px;
    }

    .media-section {
      margin-bottom: 30px;

      h3 {
        margin-bottom: 15px;
        font-size: 20px;
      }

      .form-group {
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        input {
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid $border-color;
          border-radius: 4px;
          font-size: 14px;
        }
      }

      .btn-primary {
        @extend .btn;
        background-color: $primary-color;
        border-color: $primary-color;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        i {
          margin-right: 5px;
        }
      }

      .response {
        background-color: #F1F3F5;
        padding: 15px;
        border-radius: 6px;
        border: 1px solid $border-color;
        font-size: 14px;
        color: $text-color;

        h4 {
          margin-top: 0;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          word-break: break-all;
        }
      }

      .media-url-display {
        background-color: #F1F3F5;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid $border-color;
        margin-bottom: 15px;

        p {
          margin: 0;
          font-size: 14px;
          word-break: break-all;
        }
      }
    }
  }
}

/* Estilos para Botones */
.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  text-decoration: none;

  &:hover {
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }

  &.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
</style>
