<template>
  <div class="bsp-container">
    <div class="header">
      <button class="btn btn-secondary" @click="goBack">
        <i class="fas fa-arrow-left"></i> Go Back
      </button>
    </div>
      <div class="content" v-if="!loading && whatsappBusinessAccount">
        <h1>{{ whatsappBusinessAccount.name }}</h1>
        <p class="account-id">ID: {{ whatsappBusinessAccount.id }}</p>
      <div class="account-details">
        <div class="owner-info">
          <h2>Owner Business</h2>
          <p><strong>Name:</strong> {{ whatsappBusinessAccount.owner_business_info.name }}</p>
          <p><strong>ID:</strong> {{ whatsappBusinessAccount.owner_business_info.id }}</p>
        </div>
        <div class="phone-numbers" v-if="phoneNumbersLoaded">
          <h2>Phone Numbers ({{ phoneNumbers.length }})</h2>
          <div class="phone-number-list">
            <div
              class="phone-number-item"
              v-for="phoneNumber in phoneNumbers"
              :key="phoneNumber.id"
              @click="goToPhoneNumber(phoneNumber.id)"
            >
              <i class="fas fa-phone"></i>
              <div class="phone-number-info">
                <p class="phone-name">{{ phoneNumber.verified_name }}</p>
                <p class="phone-number">{{ phoneNumber.display_phone_number }}</p>
              </div>
            </div>
          </div>
          <button class="btn btn-success" @click="openAddPhoneNumberModal">
            <i class="fas fa-plus"></i> Add Phone Number
          </button>
        </div>
        <div v-else>
          <p>Loading phone numbers...</p>
        </div>
        <div class="subscribed-apps" v-if="isSuperUser && subcribedAppsResponse">
          <h2>Subscribed Apps</h2>
          <pre>{{ subcribedAppsResponse }}</pre>
        </div>
      </div>
      <div class="actions" v-if="isSuperUser">
        <button class="btn btn-danger" @click="deleteAccount">
          <i class="fas fa-trash-alt"></i> Delete Account
        </button>
        <button class="btn btn-primary" @click="geToCreditLine">
          <i class="fas fa-credit-card"></i> Credit Line
        </button>
      </div>
      <div class="metrics-section" v-if="isSuperUser">

        <h2>Metrics</h2>
        <!-- Filtros -->
        <div class="filters">
          <div class="filter-item">
            <label for="startDate">Start Date:</label>
            <input type="date" id="startDate" v-model="filters.startDate">
          </div>
          <div class="filter-item">
            <label for="endDate">End Date:</label>
            <input type="date" id="endDate" v-model="filters.endDate">
          </div>
          <div class="filter-item">
            <label for="phoneNumbers">Phone Numbers:</label>
            <Multiselect
              v-model="filters.phoneNumbers"
              :options="phoneNumbers.map(phone => ({
                value: phone.clean_display_phone_number,
                label: phone.display_phone_number
              }))"
              track-by="value"
              label="label"
              :multiple="true"
              placeholder="Select phone numbers"
            />
          </div>
          <button @click="fetchMetrics" class="btn btn-primary">Apply Filters</button>
          <button @click="downloadReport" class="btn btn-success">
            <i class="fas fa-download"></i> Download Report
          </button>
        </div>

        <div v-if="loadingMetrics">
          <SpinnerLoader />
        </div>
        <div v-else>
        <!-- Mostrar métricas en una tabla -->
        <div class="metrics-display" v-if="aggregatedMetrics.length > 0">
            <table>
              <thead>
                <tr>
                  <th>Phone Number</th>
                  <th>Conversations</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(metric, index) in aggregatedMetrics" :key="index">
                  <td>{{ metric.phone_number }}</td>
                  <td>{{ formatNumber(metric.conversation) }}</td>
                  <td>{{ formatCost(metric.cost) }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>{{ formatNumber(totalConversations) }}</strong></td>
                  <td><strong>{{ formatCost(totalCost) }}</strong></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div v-else>
            <p>No metrics available. Please adjust your filters.</p>
          </div>
        </div>
      </div>
      <div class="api-actions">
        <h3>API Actions</h3>
        <div class="api-buttons">
          <button class="btn btn-whatsapp" @click="synchronizePhoneNumbers">
            <i class="fab fa-whatsapp"></i> Synchronize Phone Numbers
          </button>
          <button class="btn btn-whatsapp" @click="getWabaSystemUsers" v-if="isSuperUser">
            <i class="fab fa-whatsapp"></i> Get WABA System Users
          </button>
          <button class="btn btn-whatsapp" @click="createSystemUser" v-if="isSuperUser">
            <i class="fab fa-whatsapp"></i> Create System User on WABA
          </button>
          <button class="btn btn-whatsapp" @click="subscribeApp" v-if="isSuperUser">
            <i class="fab fa-whatsapp"></i> Subscribe App
          </button>
          <button class="btn btn-whatsapp" @click="getSubscribedApps" v-if="isSuperUser">
            <i class="fab fa-whatsapp"></i> Get Subscribed Apps
          </button>
        </div>
      </div>
    </div>
    <SpinnerLoader v-else />
    <!-- Modal para agregar número de teléfono -->
    <div v-if="isAddPhoneNumberModalOpen" class="modal-overlay">
      <div class="modal-content">
        <span class="close-button" @click="closeAddPhoneNumberModal">&times;</span>
        <!-- Formulario para agregar número -->
        <div v-if="!isVerificationStep">
          <h3>Add Phone Number</h3>
          <form @submit.prevent="createPhoneNumber">
            <div class="form-group">
              <label for="countryCode">Country Code:</label>
              <input type="number" id="countryCode" v-model="phoneNumberForm.cc" required>
            </div>
            <div class="form-group">
              <label for="phoneNumber">Phone Number:</label>
              <input type="number" id="phoneNumber" v-model="phoneNumberForm.phone_number" required>
            </div>
            <div class="form-group">
              <label for="verifiedName">Verified Name:</label>
              <input type="text" id="verifiedName" v-model="phoneNumberForm.verified_name" required>
            </div>
            <div class="form-group">
              <p class="policies-text">
                For the name, please remember to follow Meta's name policies
                <a href="https://web.facebook.com/business/help/757569725593362" target="_blank">here</a>.
              </p>
            </div>
            <div class="form-actions">
              <button type="submit" :disabled="!isCreateButtonEnabled" class="btn btn-primary">Create Number</button>
              <button type="button" @click="closeAddPhoneNumberModal" class="btn btn-secondary">Cancel</button>
            </div>
          </form>
        </div>
        <!-- Formulario para verificar número -->
        <div v-else>
          <h3>Verify Phone Number</h3>
          <p>Please enter the PIN code sent to your phone.</p>
          <form @submit.prevent="verifyPhoneNumber">
            <div class="form-group">
              <label for="pinCode">PIN Code:</label>
              <input type="text" id="pinCode" v-model="pinCode" required>
            </div>
            <div class="form-actions">
              <button type="submit" :disabled="!isVerifyButtonEnabled" class="btn btn-primary">Verify</button>
              <button type="button" @click="closeAddPhoneNumberModal" class="btn btn-secondary">Cancel</button>
            </div>
          </form>
          <div class="resend-pin">
            <button type="button" @click="resendPin" :disabled="isResendPinDisabled" class="btn btn-link">
              Resend PIN
            </button>
            <p v-if="isResendPinDisabled">You can resend the PIN in {{ resendPinTimer }} seconds.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import Multiselect from 'vue-multiselect'
import AuthService from '../services/AuthService/AuthService'
import { reactive, onMounted, ref, computed, watch } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { WhatsappBusinessAccount, PhoneNumber } from '../services/AccountService/types'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const phoneNumbersLoaded = ref(false)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const whatsappBusinessAccount = ref<WhatsappBusinessAccount | undefined>(undefined)

const phoneNumbers = ref<PhoneNumber[]>([])
const subcribedAppsResponse = ref<any>(undefined)

const isAddPhoneNumberModalOpen = ref(false)
const isVerificationStep = ref(false)
const phoneNumberForm = ref({
  cc: '',
  phone_number: '',
  verified_name: ''
})
const pinCode = ref('')
const isCreateButtonEnabled = computed(() => {
  return phoneNumberForm.value.cc && phoneNumberForm.value.phone_number && phoneNumberForm.value.verified_name
})
const isVerifyButtonEnabled = computed(() => {
  return pinCode.value.trim().length > 0
})
const phoneId = ref('')
const isResendPinDisabled = ref(true)
const resendPinTimer = ref(60)
let resendPinInterval: any = null

const isSuperUser = computed(() => {
  return currentUser.value?.roles.includes('superuser')
})

const metrics = ref<any[]>([])
const filteredMetrics = ref<any[]>([])
const loadingMetrics = ref(false)
const aggregatedMetrics = ref<any[]>([])

const filters = reactive({
  startDate: '',
  endDate: '',
  phoneNumbers: [] as { value: string, label: string }[]
})

const totalConversations = computed(() => {
  return aggregatedMetrics.value.reduce((sum, metric) => sum + metric.conversation, 0)
})

const totalCost = computed(() => {
  return aggregatedMetrics.value.reduce((sum, metric) => sum + metric.cost, 0)
})

function applyFilters () {
  filteredMetrics.value = metrics.value.filter(metric => {
    const matchesStartDate = !filters.startDate || new Date(metric.start) >= new Date(filters.startDate)
    const matchesEndDate = !filters.endDate || new Date(metric.end) <= new Date(filters.endDate)
    const matchesPhoneNumber = !filters.phoneNumbers.length || filters.phoneNumbers.map(phone => phone.value).includes(metric.phone_number)
    return matchesStartDate && matchesEndDate && matchesPhoneNumber
  })
  const aggregation = {}
  filteredMetrics.value.forEach(metric => {
    const phoneNumber = metric.phone_number
    if (!aggregation[phoneNumber]) {
      aggregation[phoneNumber] = {
        phone_number: phoneNumber,
        conversation: 0,
        cost: 0
      }
    }
    aggregation[phoneNumber].conversation += metric.conversation
    aggregation[phoneNumber].cost += metric.cost
  })
  aggregatedMetrics.value = Object.values(aggregation)
}

function cleanPinCode (value: string) {
  return value.replace(/\D/g, '') // Solo números
}

watch(pinCode, (newValue: string) => {
  pinCode.value = cleanPinCode(newValue)
})

function openAddPhoneNumberModal () {
  isAddPhoneNumberModalOpen.value = true
}

function closeAddPhoneNumberModal () {
  isAddPhoneNumberModalOpen.value = false
  resetAddPhoneNumberModal()
}

function resetAddPhoneNumberModal () {
  isVerificationStep.value = false
  phoneNumberForm.value = {
    cc: '',
    phone_number: '',
    verified_name: ''
  }
  pinCode.value = ''
  phoneId.value = ''
  isResendPinDisabled.value = true
  resendPinTimer.value = 60
  clearInterval(resendPinInterval)
}

async function createPhoneNumber () {
  if (whatsappBusinessAccount.value) {
    loading.value = true
    const metaService = await MetaService.getInstance()
    const payload = {
      cc: phoneNumberForm.value.cc,
      phone_number: phoneNumberForm.value.phone_number,
      verified_name: phoneNumberForm.value.verified_name
    }
    try {
      const response = await metaService.createPhoneNumber(whatsappBusinessAccount.value.id, payload)
      phoneId.value = response.phone_id
      toastSuccess('Phone number created successfully')
      isVerificationStep.value = true
      startResendPinTimer()
    } catch (error) {
      toastError('Error creating phone number: ' + error)
      closeAddPhoneNumberModal()
    }
    loading.value = false
  }
}

async function verifyPhoneNumber () {
  if (phoneId.value && whatsappBusinessAccount.value) {
    loading.value = true
    const metaService = await MetaService.getInstance()
    try {
      await metaService.verifyAndRegisterPhone(phoneId.value, pinCode.value)
      toastSuccess('Phone number verified successfully')
      closeAddPhoneNumberModal()
      await synchronizePhoneNumbers()
    } catch (error) {
      toastError('Error verifying phone number: ' + error)
    }
    loading.value = false
  }
}

function startResendPinTimer () {
  isResendPinDisabled.value = true
  resendPinTimer.value = 60
  resendPinInterval = setInterval(() => {
    resendPinTimer.value--
    if (resendPinTimer.value <= 0) {
      isResendPinDisabled.value = false
      clearInterval(resendPinInterval)
    }
  }, 1000)
}

async function resendPin () {
  if (phoneId.value && whatsappBusinessAccount.value) {
    loading.value = true
    const metaService = await MetaService.getInstance()
    try {
      await metaService.requestPhonePin(whatsappBusinessAccount.value.id, phoneId.value)
      toastSuccess('PIN code resent successfully')
      startResendPinTimer()
    } catch (error) {
      toastError('Error resending PIN code: ' + error)
    }
    loading.value = false
  }
}

function goBack () {
  router.back()
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function goToPhoneNumber (phoneNumberId: string) {
  router.push({ name: 'phone-number', params: { phoneNumberId } })
}

async function geToCreditLine () {
  router.push({ name: 'credit-line', params: { wabaId: whatsappBusinessAccount.value?.id } })
}

async function createSystemUser () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.createSystemUser(
        whatsappBusinessAccount.value.id,
        '122105581976177242'
      )
      toastSuccess('System user created' + JSON.stringify(response))
    } catch (error) {
      toastError('Error creating system user')
    }
  }
  loading.value = false
}

async function getSubscribedApps () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.getSubscribedApps(whatsappBusinessAccount.value.id)
      subcribedAppsResponse.value = response
      toastSuccess('Subscribed apps updated' + JSON.stringify(response))
    } catch (error) {
      toastError('Error updating subscribed apps')
    }
  }
  loading.value = false
}

async function subscribeApp () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.subscribeApp(whatsappBusinessAccount.value.id)
      toastSuccess('App subscribed' + JSON.stringify(response))
    } catch (error) {
      toastError('Error subscribing app')
    }
  }
  loading.value = false
}

async function getWabaSystemUsers () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.getWabaSystemUsers(whatsappBusinessAccount.value.id)
      toastSuccess('System users updated' + JSON.stringify(response))
    } catch (error) {
      toastError('Error updating system users')
    }
  }
  loading.value = false
}

async function synchronizePhoneNumbers () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      await metaService.synchronizeAccountPhoneNumbers(whatsappBusinessAccount.value.id)
      toastSuccess('Phone numbers synchronized')
      await getPhoneNumbers(whatsappBusinessAccount.value.id)
    } catch (error) {
      toastError('Error synchronizing phone numbers')
    }
  }
  loading.value = false
}

async function deleteAccount () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const accountService = await AccountService.getInstance()
    try {
      await accountService.deleteWhatsappBusinessAccount(whatsappBusinessAccount.value.id)
      toastSuccess('Account deleted')
      goBack()
    } catch (error) {
      toastError('Error deleting account: ' + error)
    }
  }
  loading.value = false
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getWhatsappBusinessAccount (wabaId: string) {
  try {
    const accountService = await AccountService.getInstance()
    whatsappBusinessAccount.value = await accountService.getWhatsappBusinessAccount(wabaId)
  } catch {
    toastError('Error getting whatsapp business account')
    router.push({ name: 'home' })
  }
}

async function getPhoneNumbers (wabaId: string) {
  try {
    const accountService = await AccountService.getInstance()
    phoneNumbers.value = await accountService.getWhatsappBusinessAccountPhoneNumbers(wabaId)
    filters.phoneNumbers = phoneNumbers.value.map(phone => ({
      value: phone.clean_display_phone_number,
      label: phone.display_phone_number
    }))
  } catch {
    toastError('Error getting whatsapp business account')
  }
}

async function downloadReport () {
  if (whatsappBusinessAccount.value) {
    loadingMetrics.value = true
    try {
      const metaService = await MetaService.getInstance()
      const params = {
        report: true
      }
      if (filters.startDate && filters.endDate) {
        params.start = filters.startDate
        params.end = filters.endDate
      }
      if (filters.phoneNumbers.length > 0) {
        params.phone_numbers = filters.phoneNumbers.map(phone => phone.value).join(',')
      }
      const response = await metaService.downloadConversationReport(
        whatsappBusinessAccount.value.id,
        params
      )
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'conversation_report.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      toastSuccess('Report downloaded successfully')
    } catch (error) {
      toastError('Error downloading report: ' + error)
    }
    loadingMetrics.value = false
  }
}

onMounted(async () => {
  await router.isReady()
  await getCurrentUser()
  await getEstablishment()
  const wabaId = route.params.wabaId as string

  if (wabaId) {
    await getWhatsappBusinessAccount(wabaId)
    await getPhoneNumbers(wabaId)
    await fetchMetrics()
    phoneNumbersLoaded.value = true
    loading.value = false
  } else {
    router.push({ name: 'home' })
  }
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

async function fetchMetrics () {
  loadingMetrics.value = true
  try {
    const metaService = await MetaService.getInstance()
    const params = {}
    if (filters.startDate && filters.endDate) {
      params.start = filters.startDate
      params.end = filters.endDate
    }
    if (filters.phoneNumbers.length > 0) {
      params.phone_numbers = filters.phoneNumbers.map(phone => phone.value).join(',')
    }
    const response = await metaService.getConversationAnalytics(
      whatsappBusinessAccount.value.id,
      params
    )
    metrics.value = response.data_points
    applyFilters()
  } catch (error) {
    toastError('Error fetching metrics: ' + error)
  }
  loadingMetrics.value = false
}
function formatCost (value) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
}
function formatNumber (value) {
  return new Intl.NumberFormat('en-US').format(value)
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped lang="scss">
/* Variables de Color */
$primary-color: #0052CC;
$secondary-color: #6B778C;
$success-color: #36B37E;
$danger-color: #FF5630;
$whatsapp-color: #25D366;
$background-color: #F4F5F7;
$text-color: #172B4D;
$border-color: #DFE1E6;

/* Importar Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.bsp-container {
  padding: 20px;
  background-color: $background-color;
  color: $text-color;
  min-height: 100vh;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .btn {
      margin-right: 20px;
      display: flex;
      align-items: center;

      i {
        margin-right: 5px;
      }
    }

    h1 {
      margin: 0;
      font-size: 28px;
    }

    .account-id {
      margin-left: auto;
      font-size: 14px;
      color: $secondary-color;
    }
  }

  .content {
    max-width: 1000px;
    margin: 0 auto;

    .account-details {
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;

      .owner-info {
        margin-bottom: 30px;

        h2 {
          margin-top: 0;
          border-bottom: 1px solid $border-color;
          padding-bottom: 10px;
        }

        p {
          margin: 5px 0;
        }
      }

      .phone-numbers {
        margin-bottom: 30px;

        h2 {
          border-bottom: 1px solid $border-color;
          padding-bottom: 10px;
          margin-top: 0;
        }

        .phone-number-list {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          margin-top: 20px;

          .phone-number-item {
            background-color: #fff;
            border: 1px solid $border-color;
            border-radius: 6px;
            padding: 15px;
            width: calc(33.333% - 10px);
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: background-color 0.2s;

            &:hover {
              background-color: $background-color;
            }

            i {
              font-size: 24px;
              color: $primary-color;
              margin-right: 15px;
            }

            .phone-number-info {
              .phone-name {
                font-size: 16px;
                font-weight: bold;
                margin: 0;
              }

              .phone-number {
                font-size: 14px;
                color: $secondary-color;
                margin: 0;
              }
            }
          }
        }

        .btn {
          margin-top: 20px;
          display: flex;
          align-items: center;

          i {
            margin-right: 5px;
          }
        }
      }

      .subscribed-apps {
        h2 {
          border-bottom: 1px solid $border-color;
          padding-bottom: 10px;
          margin-top: 0;
        }

        pre {
          background-color: #F1F3F5;
          padding: 15px;
          border-radius: 6px;
          border: 1px solid $border-color;
          max-height: 300px;
          overflow: auto;
          font-size: 14px;
          color: $text-color;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-bottom: 20px;

      .btn {
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
        }
      }
    }

    .api-actions {
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 20px;

      h3 {
        margin-top: 0;
        border-bottom: 1px solid $border-color;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }

      .api-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .btn {
          display: flex;
          align-items: center;
          padding: 10px 15px;

          i {
            margin-right: 5px;
          }
        }
      }
    }
  }

  /* Estilos del Modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

    .close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 28px;
      font-weight: bold;
      color: $secondary-color;
      cursor: pointer;

      &:hover {
        color: $text-color;
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    form {
      .form-group {
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        input {
          width: 100%;
          padding: 8px;
          box-sizing: border-box;
          border: 1px solid $border-color;
          border-radius: 4px;
        }
      }

      .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;

        .btn {
          padding: 10px 20px;
        }
      }
    }

    .policies-text {
      font-size: 12px;
      color: $secondary-color;

      a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .resend-pin {
      margin-top: 20px;
      text-align: right;

      .btn-link {
        padding: 0;
        background: none;
        border: none;
        color: $primary-color;
        cursor: pointer;

        &:disabled {
          color: $secondary-color;
          cursor: not-allowed;
        }
      }

      p {
        font-size: 12px;
        color: $secondary-color;
        margin-top: 5px;
      }
    }
  }
}

/* Estilos para Botones */
.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 1rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  text-decoration: none;

  &:hover {
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }

  &.btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
      background-color: darken($secondary-color, 5%);
      border-color: darken($secondary-color, 5%);
    }
  }

  &.btn-success {
    background-color: $success-color;
    border-color: $success-color;

    &:hover {
      background-color: darken($success-color, 5%);
      border-color: darken($success-color, 5%);
    }
  }

  &.btn-danger {
    background-color: $danger-color;
    border-color: $danger-color;

    &:hover {
      background-color: darken($danger-color, 5%);
      border-color: darken($danger-color, 5%);
    }
  }

  &.btn-whatsapp {
    background-color: $whatsapp-color;
    border-color: $whatsapp-color;

    &:hover {
      background-color: darken($whatsapp-color, 5%);
      border-color: darken($whatsapp-color, 5%);
    }
  }

  &.btn-link {
    background: none;
    border: none;
    color: $primary-color;
    padding: 0;
    font-weight: normal;

    &:hover {
      color: darken($primary-color, 5%);
      text-decoration: underline;
    }

    &:disabled {
      color: $secondary-color;
      cursor: not-allowed;
    }
  }
}

.metrics-section {
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  h2 {
    margin-top: 0;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .filter-item {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
        margin-bottom: 5px;
      }

      input {
        padding: 5px;
        border: 1px solid $border-color;
        border-radius: 4px;
      }
    }

    .btn {
      align-self: flex-end;
      margin-left: auto;
    }
  }

  .metrics-display {
    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid $border-color;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: $background-color;
      }
    }
  }
}
</style>
