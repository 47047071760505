<template>
  <div class="home-container">
    <div class="home" v-if="!loading">
      <div class="client-view" v-if="currentUser && currentEstablishment">
        <!-- Sección de Bienvenida -->
        <div class="welcome-section">
          <h1>Welcome, {{ currentUser.name }}</h1>
          <p>Your establishment: <strong>{{ currentEstablishment.name }}</strong></p>
        </div>

        <!-- Sección de Registro de WhatsApp -->
        <div class="whatsapp-login" v-if="!currentEstablishment.has_whatsapp_credentials">
          <button class="btn-primary" @click="startEmbeddedSignupFlow">
            <i class="fab fa-whatsapp"></i> WhatsApp Signup
          </button>
        </div>

        <!-- Sección de Cuentas de WhatsApp Business -->
        <div class="whatsapp-accounts-container">
          <div class="whatsapp-accounts-list" v-if="whatsappBusinessAccounts.length > 0">
            <h2>Your WhatsApp Business Accounts</h2>
            <div
              class="whatsapp-account"
              v-for="whatsappAccount in whatsappBusinessAccounts"
              :key="whatsappAccount.id"
              @click="goToWhatsappBusinessAccount(whatsappAccount.id)"
            >
              <div class="whatsapp-account-info">
                <h3>{{ whatsappAccount.name }}</h3>
                <p>ID: {{ whatsappAccount.id }}</p>
              </div>
              <div class="whatsapp-account-logo">
                <i class="fab fa-whatsapp"></i>
              </div>
            </div>
          </div>
          <div class="whatsapp-synchronize-button">
            <p>
              Can't see your WABAs? Synchronize your accounts!
            </p>
            <button class="btn-secondary" @click="SynchronizeWhatsappBusinessAccounts">
              <i class="fas fa-sync-alt"></i> Get WABAs
            </button>
          </div>
        </div>

        <!-- Sección de Botones de Configuración -->
        <div class="config-buttons" v-if="isSuperUser">
          <button class="btn-secondary" @click="goToMediaTools">
            <i class="fas fa-photo-video"></i> Media Tools
          </button>
          <button class="btn-secondary" @click="goToConfigClient">
            <i class="fas fa-cog"></i> Config Client
          </button>
        </div>
      </div>

      <!-- Vista cuando no hay establecimiento -->
      <div class="no-client-view" v-else>
        <div class="no-establishment-message">
          <h2>You don't have an establishment 🏛️</h2>
          <p>You can create one now or wait for an invitation to join an existing one.</p>
        </div>
        <div class="create-establishment-form">
          <h2>Create Establishment</h2>
          <div class="form-group">
            <label for="establishmentName">Establishment's name</label>
            <input
              type="text"
              id="establishmentName"
              v-model="establishmentName"
              placeholder="Your Brand"
            />
          </div>
          <button class="btn-primary" @click="createEstablishment">
            Create
          </button>
        </div>
      </div>
    </div>
    <SpinnerLoader v-else />
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref, computed } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { WhatsappBusinessAccount } from '../services/AccountService/types'
import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
const establishmentName = ref('')
const whatsappBusinessAccounts = ref<Array<WhatsappBusinessAccount>>([])

const isSuperUser = computed(() => {
  return currentUser.value?.roles.includes('superuser')
})

async function goToWhatsappBusinessAccount (wabaId: string) {
  router.push({ name: 'whatsapp-business-account', params: { wabaId } })
}

async function goToMediaTools () {
  router.push({ name: 'media-tools' })
}

async function goToConfigClient () {
  router.push({ name: 'config-client' })
}

async function getAccounts () {
  const accountService = await AccountService.getInstance()
  try {
    whatsappBusinessAccounts.value = await accountService.getWhatsappBusinessAccounts()
  } catch (error) {
    // do nothing
  }
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  if (currentEstablishment.value) {
    await getAccounts()
  }

  loading.value = false
})

async function createEstablishment () {
  if (establishmentName.value === '') {
    toastError('El nombre del establecimiento es requerido')
    return
  }
  const establishmentService = await EstablishmentService.getInstance()
  await establishmentService.createEstablishment(establishmentName.value)
  router.push('/')
}

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

async function SynchronizeWhatsappBusinessAccounts () {
  loading.value = true
  const metaService = await MetaService.getInstance()
  await metaService.synchronizeWhatsappBusinessAccounts()
  await getAccounts()
  loading.value = false
}

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function startEmbeddedSignupFlow () {
  // Facebook's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://www.facebook.com/v18.0/dialog/oauth'

  // Parameters to pass to the OAuth 2.0 endpoint.
  const params = {
    client_id: '705015111174876',
    redirect_uri: 'https://whatsapp.adere.so/home',
    response_type: 'code',
    config_id: '367108835655082',
    state: 'pass-through value'
  }

  // Construct the OAuth URL with parameters
  const oauthURL = `${oauth2Endpoint}?${new URLSearchParams(params).toString()}`

  // Open a new window for the OAuth flow
  window.location.replace(oauthURL)
}

</script>

<style scoped lang="scss">
/* Variables de Color */
$primary-color: #0052CC;
$secondary-color: #6B778C;
$success-color: #36B37E;
$danger-color: #FF5630;
$whatsapp-color: #25D366;
$background-color: #F4F5F7;
$text-color: #172B4D;
$border-color: #DFE1E6;

/* Importar Font Awesome */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Estilos Generales */
.home-container {
  padding: 20px;
  background-color: $background-color;
  color: $text-color;
  min-height: 100vh;

  .home {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;

    .client-view {
      width: 100%;
      max-width: 1200px;

      .welcome-section {
        text-align: center;
        margin-bottom: 20px;

        h1 {
          margin-bottom: 5px;
          font-size: 28px;
        }

        p {
          margin-top: 0;
          font-size: 18px;
          color: $secondary-color;
        }
      }

      .whatsapp-login {
        margin: 20px 0;
        text-align: center;

        .btn-primary {
          @extend .btn;
          background-color: $primary-color;
          border-color: $primary-color;

          i {
            margin-right: 10px;
            font-size: 20px;
          }
        }
      }

      .whatsapp-accounts-container {
        width: 100%;

        .whatsapp-accounts-list {
          margin-bottom: 20px;

          h2 {
            margin-bottom: 15px;
            font-size: 24px;
          }

          .whatsapp-account {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border: 1px solid $border-color;
            border-radius: 6px;
            padding: 15px;
            cursor: pointer;
            transition: background-color 0.2s, box-shadow 0.2s;

            &:hover {
              background-color: $background-color;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            }

            .whatsapp-account-info {
              h3 {
                margin: 0;
                font-size: 20px;
              }

              p {
                margin: 5px 0 0 0;
                font-size: 14px;
                color: $secondary-color;
              }
            }

            .whatsapp-account-logo {
              i {
                font-size: 40px;
                color: $whatsapp-color;
              }
            }
          }
        }

        .whatsapp-synchronize-button {
          text-align: center;

          p {
            margin-bottom: 10px;
            color: $secondary-color;
          }

          .btn-secondary {
            @extend .btn;
            background-color: $secondary-color;
            border-color: $secondary-color;

            i {
              margin-right: 10px;
            }
          }
        }
      }

      .config-buttons {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 20px;

        .btn-secondary {
          @extend .btn;
          background-color: $secondary-color;
          border-color: $secondary-color;

          i {
            margin-right: 10px;
          }
        }
      }
    }

    .no-client-view {
      text-align: center;

      .no-establishment-message {
        margin-bottom: 30px;

        h2 {
          margin-bottom: 10px;
          font-size: 24px;
        }

        p {
          margin: 0;
          color: $secondary-color;
        }
      }

      .create-establishment-form {
        background-color: #fff;
        border: 1px solid $border-color;
        border-radius: 6px;
        padding: 30px;
        max-width: 400px;
        margin: 0 auto;

        h2 {
          margin-top: 0;
          margin-bottom: 20px;
          font-size: 24px;
        }

        .form-group {
          margin-bottom: 20px;
          text-align: left;

          label {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
          }

          input {
            width: 100%;
            padding: 10px;
            border: 1px solid $border-color;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: 14px;
          }
        }

        .btn-primary {
          @extend .btn;
          background-color: $primary-color;
          border-color: $primary-color;
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }
}

/* Estilos para Botones */
.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  text-decoration: none;

  &:hover {
    background-color: darken($primary-color, 5%);
    border-color: darken($primary-color, 5%);
  }

  &.btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
      background-color: darken($secondary-color, 5%);
      border-color: darken($secondary-color, 5%);
    }
  }

  &.btn-success {
    background-color: $success-color;
    border-color: $success-color;

    &:hover {
      background-color: darken($success-color, 5%);
      border-color: darken($success-color, 5%);
    }
  }

  &.btn-danger {
    background-color: $danger-color;
    border-color: $danger-color;

    &:hover {
      background-color: darken($danger-color, 5%);
      border-color: darken($danger-color, 5%);
    }
  }

  &.btn-whatsapp {
    background-color: $whatsapp-color;
    border-color: $whatsapp-color;

    &:hover {
      background-color: darken($whatsapp-color, 5%);
      border-color: darken($whatsapp-color, 5%);
    }
  }

  &.btn-link {
    background: none;
    border: none;
    color: $primary-color;
    padding: 0;
    font-weight: normal;

    &:hover {
      color: darken($primary-color, 5%);
      text-decoration: underline;
    }

    &:disabled {
      color: $secondary-color;
      cursor: not-allowed;
    }
  }
}
</style>
