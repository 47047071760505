import HttpService from '../HttpService/HttpService'
import { WhatsappBusinessAccount, PhoneNumber } from './types'

export default class AccountService {
  private constructor () {
    // Check if an instance already exists and return it
    if (instance) {
      return instance
    }
    // If no instance exists, create one and assign it to the 'instance' variable
    instance = this
  }

  static async getInstance () {
    const accountService = new AccountService()
    return accountService
  }

  async getWhatsappBusinessAccounts (): Promise<Array<WhatsappBusinessAccount>> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get('/api/v1/whatsapp_business_accounts')
    return response
  }

  async getWhatsappBusinessAccount (wabaId: string): Promise<WhatsappBusinessAccount> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/whatsapp_business_accounts/${wabaId}`)
    return response
  }

  async updateWhatsappBusinessAccount (account: WhatsappBusinessAccount): Promise<WhatsappBusinessAccount> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.patch(`/api/v1/whatsapp_business_accounts/${account.id}`, account)
    return response
  }

  async getWhatsappBusinessAccountPhoneNumbers (wabaId: string): Promise<PhoneNumber[]> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/whatsapp_business_accounts/${wabaId}/phone_numbers`)
    return response
  }

  async getAllPhoneNumbers (): Promise<PhoneNumber[]> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get('/api/v1/admin/phone_numbers')
    return response
  }

  async deleteWhatsappBusinessAccount (wabaId: string): Promise<void> {
    const httpService = await HttpService.getInstance()
    await httpService.delete(`/api/v1/whatsapp_business_accounts/${wabaId}`)
  }

  async getphoneNumber (wabaId: string, phoneNumberId: string): Promise<PhoneNumber> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/whatsapp_business_accounts/${wabaId}/phone_numbers/${phoneNumberId}`)
    return response
  }

  async updatePhoneNumber (phoneNumber: PhoneNumber): Promise<PhoneNumber> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.patch(`/api/v1/whatsapp_business_accounts/${phoneNumber.waba_id}/phone_numbers/${phoneNumber.id}`, phoneNumber)
    return response
  }

  async deletePhoneNumber (wabaId: string, phoneNumberId: string): Promise<void> {
    const httpService = await HttpService.getInstance()
    await httpService.delete(`/api/v1/whatsapp_business_accounts/${wabaId}/phone_numbers/${phoneNumberId}`)
  }

  async getMessageTemplates (wabaId: string, phoneNumberId: string): Promise<Array<any>> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get(`/api/v1/whatsapp_business_accounts/${wabaId}/phone_numbers/${phoneNumberId}/message_templates`)
    return response
  }

  async deleteMessageTemplate (wabaId: string, phoneNumberId: string, messageTemplateId: string): Promise<void> {
    const httpService = await HttpService.getInstance()
    await httpService.delete(`/api/v1/whatsapp_business_accounts/${wabaId}/phone_numbers/${phoneNumberId}/message_templates/${messageTemplateId}`)
  }

  async createMessageTemplate (wabaId: string, phoneNumberId: string, messageTemplate: any): Promise<any> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post(`/api/v1/whatsapp_business_accounts/${wabaId}/phone_numbers/${phoneNumberId}/message_templates`, messageTemplate)
    return response
  }
}

let instance: AccountService | null = null // Singleton instance
